html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #FAFAFA;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

/* stylelint-disable selector-max-id */
#app {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: white;
}
/* stylelint-enable selector-max-id */

.route-content {
  margin: 0 10px 0 0;
  padding: 20px;
  width: calc(100% - 300px);
  max-width: calc(100% - 300px);
}

.side-nav {
  min-width: 265px;
  max-width: 280px;
}

@media (max-width: 880px) {
  /* stylelint-disable selector-max-id */
  #app {
    flex-wrap: wrap;
  }
  /* stylelint-enable selector-max-id */

  .route-content {
    margin: 0 10px 0 10px;
  }
}

.headline {
  font-size: 28px;
  color: #7f7f7f;
  font-weight: bold;
  margin-bottom: 10px;
}

.fileContainer {
  padding: 0 !important;
  text-align: center;
}

.doubleInput {
  display: flex;
}

.doubleInput > div {
  width: 49%;
}

.doubleInput > div:first-child {
  margin-right: 2%;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 5px !important;
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: #ececec;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e3002b !important;
  border-color: #7f7f7f !important;
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.spinContainer {
  text-align: center;
  margin: 30px 0;
}

.errorContainer {
  color: #e3002b !important;
  text-align: center;
  font-size: 28px;
  margin: 30px 10px;
}

.errorContainer .errorMsg {
  font-size: 20px;
}

.errorContainer .notifMsg {
  font-size: 20px;
  color: green !important;
}

.errorSamllMsg {
  color: #e3002b !important;
  text-align: center;
  font-size: 18px;
}

button {
  color: #ffffff;
  background-color: #4d4c4c;
  padding: 5px;
  border-color: transparent;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

button.endCall {
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background: none;
}

button.cancelBtn {
  color: #979797 !important;
  background-color: transparent;
  width: auto;
  padding: 5px 15px;
  border-radius: 0;
  border-bottom: 1px solid #979797;
}

button.saveBtn {
  color: #ffffff !important;
  background-color: #e3002b;
  width: auto;
  padding: 5px 15px;
  border-bottom: 1px solid #979797;
}

button:disabled {
  background-color: #979797;
  cursor: default;
}

input,
button {
  outline: none;
}

ul.typesDescription {
  font-size: 14px;
}

ul.typesDescription li {
  list-style: none;
}

ul.typesDescription span {
  color: #444;
  margin-right: 15px;
  display: inline-block;
  min-width: 130px;
  text-align: center;
}

.table-selectable-name {
  cursor: pointer;
  color: #444;
}

.table-selectable-name:hover {
  text-decoration: underline;
}

.emoji-text-field .emoji-trigger {
  top: 4px !important;
}

.confirmModal {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  background-color: white;
  border: 2px solid #444;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
}

.confirmModal button {
  width: 70px !important;
  height: 35px !important;
  margin: 0 10px;
}

.confirmModal .confirmTitle {
  color: #e3002b;
  margin-bottom: 20px;
}

.confirmModal .confirmOkBtn {
  color: white !important;
  background-color: #e3002b;
}

.confirmModal .confirmCancelBtn {
  color: black !important;
  background-color: white;
  border: 2px solid black;
}

.emoji-picker {
  z-index: 5;
}

.ant-collapse.ant-collapse-icon-position-left {
  border: 0 none;
  background-color: #e1e2f5;
  margin-bottom: 30px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.featuredService .ant-input,
.featuredService textarea {
  font-size: 15px !important;
}

.featuredService .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green !important;
}

.deleteService,
.deletePartner,
.deleteCountry {
  width: 100%;
  margin-top: 10px;
  background-color: #e3002b;
}

.editService,
.editPartner,
.editCountry {
  width: 100%;
  background-color: #1890ff;
}

.featuredServiceCard button.ant-switch {
  width: auto;
}
