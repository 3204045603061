.mainLayout {
    width: 100%;
    height: 100%;
}

.layoutWrapper {
    margin-top: 110px;
    width: 100%;
    overflow-x: auto;
}

.layout {
    min-width: 1200px;
    padding: 0 20px 0;
    display: flex;
}

