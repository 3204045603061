.fieldGroup {
  width: 100%;
  padding: 0 0 10px;
}

.fieldGroup :global(input),
.fieldGroup :global(select),
.fieldGroup :global(textarea) {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.fieldLabel {
  font-size: 18px;
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.fieldLabel + div {
  margin: 0 !important;
}

.fieldGroup :global(input) {
  padding-left: 10px;
  padding-right: 10px;
}

.fieldError {
  color: #e3002b;
  font-size: 14px;
  margin-top: 2px;
}

.partnersWrap {
  display: flex;
  flex-wrap: wrap;
}

.partnersWrap .partner {
  width: 50%;
}
