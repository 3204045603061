.partnersWrap {
  display: flex;
  flex-wrap: wrap;
}

.partnersWrap .partner {
  width: 25%;
}

.controls {
  text-align: right;
  min-height: 38px;
}

.controls button:not(:last-child) {
  margin-right: 10px;
}
